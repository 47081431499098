import { useState } from "react";
import { Stars } from "./components/Stars";
import { useRequestAndNotify } from "./hooks/useRequest";
import { SendReviewProps, sendReview } from "./hooks/requests/sendReview";
import { getRatingFromNumber } from "./helpers/stars";
import { toast } from "react-toastify";
import env from "react-dotenv";

export const App = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const objectId = queryParameters.get("o");
  const niceNumber = queryParameters.get("n");

  const [starsValue, setStarsValue] = useState(0);
  const [emailValue, setEmailValue] = useState("");
  const [noteValue, setNoteValue] = useState("");

  const handleSubmit = () => {
    const rating = getRatingFromNumber(starsValue);

    if (!objectId || !niceNumber) {
      toast.error("Vyskytl se problém. Prosím, načtěte QR kód znovu.");
      return;
    }

    if (!rating && !noteValue) {
      toast.error("Prosím, ohodnoťte objednávku hvězdičkami nebo poznámkou");
      return;
    }

    addToContacts({
      objectId: objectId ?? "",
      niceNumber: niceNumber ?? "",
      rating: rating,
      email: emailValue,
      feedback: noteValue,
    });
  };

  const [addToContacts] = useRequestAndNotify({
    fn: async (props: SendReviewProps) => {
      await sendReview(props);
    },
    onSuccess: "Úspěšně odesláno!",
    onError: "Nepovedlo se odeslat.",
  });

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEmailValue(e.target.value);

  const handleChangeNote = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setNoteValue(e.target.value);

  return (
    <div className="flex flex-row justify-center">
      <div
        style={{ maxWidth: "2000px" }}
        className="w-full bg-red text-white flex lg:flex-row flex-col justify-evenly items-center p-8 min-h-screen shadow-lg"
      >
        <div className="lg:w-1/2 lg:h-full h-50 flex flex-row justify-center items-center">
          <img
            className="lg:max-w-full max-w-sm max-h-full"
            src="/img/logo-white.png"
          />
        </div>
        <div className="max-w-md w-full flex flex-col gap-y-4">
          {env.API_URL === "development" && (
            <div>
              <div>Object ID: {objectId}</div>
              <div>Hash: {niceNumber}</div>
            </div>
          )}
          <Stars
            className="w-full"
            value={starsValue}
            onChange={setStarsValue}
          />
          <input
            type="text"
            value={emailValue}
            onChange={handleChangeEmail}
            placeholder="Zadejte svůj e-mail"
          />
          <textarea
            value={noteValue}
            onChange={handleChangeNote}
            placeholder="Ohodnoťte Vaši objednávku"
          />
          <button className="submit" onClick={handleSubmit}>
            Odeslat
          </button>
        </div>
      </div>
    </div>
  );
};
